
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import FilterModel from "@/views/page/components/filter-model";
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";
import ViewFilter from "@/views/components/filter-component/logs-view-filter.vue";

@Options({
  name: "FilterComponent",
  components: {
    LogsViewFilter,
    ViewFilter,
  },
})
export default class FilterComponent extends Vue {
  @Prop({ default: true }) slectSource: boolean;
  @Prop({ default: true }) selectData: boolean;
  @Prop({ default: true }) selectInterval: boolean;
  @Prop({ default: true }) selectLogsFilter: boolean;
  @Prop({ default: true }) selectViewFilter: boolean;
  @Prop({ default: true }) selectButton: boolean;
  @Prop({ default: true }) methods: boolean;
  @Prop({ default: true }) logsType: boolean;
  @Prop({ default: true }) logsLevel: boolean;
  @Prop({ default: true }) url: boolean;
  @Prop({ default: true }) requestId: boolean;
  @Prop({ default: true }) loggerName: boolean;
  @Prop({ default: true }) sessionToken: boolean;
  @Prop({ default: true }) ip: boolean;
  @Prop({ default: true }) userId: boolean;
  @Prop({ default: true }) connectionId: boolean;
  @Prop({ default: true }) shortMessage: boolean;
  @Prop({ default: false }) liveReload: boolean;
  @Prop({ default: false }) projectLogGroupId: boolean;

  innerFilter: FilterModel = new FilterModel();
  isLiveReload: boolean = false;

  mounted() {
    this.getLogsViewFilterDataStor()
  }

  changeSource(value: number) {
    this.getLogsViewFilterDataStor()
    this.innerFilter.ProjectLogGroupId = value;
    this.saveInLocalStor()
  }

  //сохранить модель фильтра для страницы
  saveInLocalStor() {
    localStorage.logsViewFilter = JSON.stringify(
      this.innerFilter
    );
  }

  getLogsViewFilterDataStor() {
    if (localStorage.logsViewFilter) {
      this.innerFilter = JSON.parse(localStorage.logsViewFilter);
      const t = 0// 0//new Date().getTimezoneOffset();
      this.innerFilter.DateFrom = this.addMinutes(this.innerFilter.DateFrom, t);
      this.innerFilter.DateTo = this.addMinutes(this.innerFilter.DateTo, t);
      return
    }
  }

  changeDateFrom(value) {
    this.saveInLocalStor()
  }
  changeInterval(value: number) {
    this.innerFilter.interval = value;
  }

  filterData() {
    this.saveInLocalStor()
  }

  showData() {
    this.$emit("filterData", this.innerFilter);
  }

  skipPeriod(value: number) {
    const period = this.innerFilter.DateTo.getTime() - this.innerFilter.DateFrom.getTime();
    if (value === -1) {
      this.innerFilter.DateTo = new Date(this.innerFilter.DateFrom);
      let DateFrom = new Date()
      DateFrom.setTime(this.innerFilter.DateFrom.getTime() - period);
      this.innerFilter.DateFrom = DateFrom;
    } else {
      this.innerFilter.DateFrom = new Date(this.innerFilter.DateTo);
      let DateTo = new Date()
      DateTo.setTime(this.innerFilter.DateTo.getTime() + period);
      this.innerFilter.DateTo = DateTo;
    }
    this.filterData()
  }
  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }

  onLiveReload() {
    this.isLiveReload = !this.isLiveReload;
    this.$emit("onLiveReload", this.isLiveReload);
  }
}
