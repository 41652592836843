
import { Options, Prop, Vue } from "vue-property-decorator";
import { reactive } from "vue";
@Options({
  name: "LayoutSidebar",
})
export default class LayoutSidebar extends Vue {
  @Prop() hideMenu: boolean;

  minimized: boolean = false;
  min: boolean = false;
  activeArt = false;
  currentRoute = "";
  currentSubMenu = "";
  ver = "1";
  activeMenu = 0;
  apiUrl;
  setActivMenu(value: number) {
    this.activeMenu = value;
    switch (value) {
      case 1:
        this.$router.push({ name: 'active-logs-view'})
        break;
      case 2:
        this.$router.push({ name: 'content-logs-view'})
        break;
      case 3:
        this.$router.push({ name: 'http-logs-view'})
        break;
      case 4:
        this.$router.push({ name: 'ws-logs-view'})
        break;
   
    }

    return false;
  }
  async loadSettings() {
    try {
      // Запрашиваем файл appWebSettings.json из папки public
      const response = await fetch("/appWebSettings.json");

      // Проверяем, что запрос успешен
      if (!response.ok) {
        throw new Error("Не удалось загрузить настройки");
      }

      // Преобразуем ответ в JSON
      const settings = await response.json();

      // Сохраняем нужные параметры в переменные
      this.ver = settings.ver;
    } catch (error) {
      console.error("Ошибка при загрузке настроек:", error);
    }
  }

  mounted() {
    this.loadSettings();
    this.currentRoute = this.$route.name.toString();
    this.initMenu();

    const startWindowWidth = window.innerWidth;
    this.stateNavPanel(startWindowWidth);

    window.onresize = (event) => {
      const windowWidth = event.target.innerWidth;
      this.stateNavPanel(windowWidth);
    };

    // if (this.currentRoute === "content-logs-view") this.activeMenu = 1;

    // console.log(`currentRoute`, this.currentRoute);
  }
  beforeUpdate() {
    // this.currentRoute = this.$route.name.toString();
    // this.initMenu();
  }
  updated() {
    // console.log("updated");
  }
  stateNavPanel(currentWindowWidth: number) {
    if (this.hideMenu) {
      return;
    }
    this.$emit("update:hideMenu", currentWindowWidth < 1000);
  }

  goHome() {
    this.$router.push({ path: "/" });
  }

  // showSubMenu(value: string = null) {
  //   this.activeClassContentLogs();
  //   this.activeClassMetodLogs();
  //   // this.currentRoute = value;
  //   // if (value) {
  //   //   this.$router.push({ name: value });
  //   // }
  //   this.initMenu();
  // }
  initMenu() {
    this.currentSubMenu = "";
    // if (
    //   this.currentRoute === "metod-list" ||
    //   this.currentRoute === "top-height-methods-load" ||
    //   this.currentRoute === "logs-statistics" ||
    //   this.currentRoute === "logs-view-errors"
    // ) {
    //   this.currentSubMenu = "metod-list";
    // } else {
    //   this.currentSubMenu = "";
    // }

    // if (
    //   this.currentRoute === "content-logs-view" ||
    //   this.currentRoute === "content-logs-view-errors"
    // ) {
    //   this.currentSubMenu = "content-logs-view";
    // }
    if (
      this.currentRoute === "active-logs-view" ||
      this.currentRoute === "metod-list"||
      this.currentRoute === "average-response-time-of-methods"||
      this.currentRoute === "logs-statistics"||
      this.currentRoute === "top-height-methods-load"||
      this.currentRoute === "logs-view"||
      this.currentRoute === "number-active-users"||
      this.currentRoute === "number-of-open-and-closed-sessions"
    ) {
      this.currentSubMenu = "active-logs-view";
      this.activeMenu = 1;
    }
    if (
      this.currentRoute === "content-logs-view" ||
      this.currentRoute === "content-logs-view-active"
    ) {
      // this.currentSubMenu = "active-logs-view";
      this.activeMenu = 2;
    }
    if (
      this.currentRoute === "http-logs-view" ||
      this.currentRoute === "http-top-height-methods-load"||
      this.currentRoute === "http-active-logs-view"
    ) {
      this.currentSubMenu = "active-logs-view";
      this.activeMenu = 3;
    }
    if (
      this.currentRoute === "ws-logs-view" ||
      this.currentRoute === "ws-active-logs-view"
    ) {
      this.currentSubMenu = "active-logs-view";
      this.activeMenu = 4;
    }
  }
}
