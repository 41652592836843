import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mx-2"
}
const _hoisted_2 = {
  key: 1,
  class: "mx-2"
}
const _hoisted_3 = {
  key: 2,
  class: "mx-2"
}
const _hoisted_4 = {
  key: 3,
  class: "mx-2"
}
const _hoisted_5 = { class: "ui-input mb-3" }
const _hoisted_6 = { class: "ui-input mb-3" }
const _hoisted_7 = { class: "ui-input mb-3" }
const _hoisted_8 = { class: "ui-input mb-3" }
const _hoisted_9 = { class: "ui-input mb-3" }
const _hoisted_10 = { class: "ui-input mb-3" }
const _hoisted_11 = { class: "ui-input mb-3" }
const _hoisted_12 = { class: "ui-input mb-3" }
const _hoisted_13 = { class: "ui-input mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tirscript3_dropdown = _resolveComponent("tirscript3-dropdown")!
  const _component_bs_row = _resolveComponent("bs-row")!
  const _component_bs_col = _resolveComponent("bs-col")!
  const _component_tirscript3_button = _resolveComponent("tirscript3-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_bs_row, { class: "mb-2" }, {
      default: _withCtx(() => [
        _createVNode(_component_bs_col, { style: {"background":"#eee"} }, {
          default: _withCtx(() => [
            _createVNode(_component_bs_row, { class: "my-2 px-2" }, {
              default: _withCtx(() => [
                (_ctx.methods)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_tirscript3_dropdown, {
                        placeholder: "Контроллер/метод",
                        items: _ctx.controllerMethods,
                        onChange: _ctx.onChangeControllerMethods,
                        autocomplete: "",
                        onChangeAutocomlite: _ctx.onChangeAutocomlite
                      }, null, 8, ["items", "onChange", "onChangeAutocomlite"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.logsType)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_tirscript3_dropdown, {
                        placeholder: "Типы логов",
                        items: _ctx.logsTypes,
                        onChange: _ctx.onChangeLogsType
                      }, null, 8, ["items", "onChange"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.logsLevels)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_tirscript3_dropdown, {
                        placeholder: "Уровень логов",
                        items: _ctx.logsLevels,
                        onChange: _ctx.onChangeLogsLevel
                      }, null, 8, ["items", "onChange"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hardwareLogsType)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_tirscript3_dropdown, {
                        placeholder: "Тип мониторинга",
                        items: _ctx.hardwareLogsTypes,
                        onChange: _ctx.onChangeHardwareLogsType
                      }, null, 8, ["items", "onChange"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.addPanel)
      ? (_openBlock(), _createBlock(_component_bs_row, {
          key: 0,
          class: "mb-2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_bs_col, { style: {"background":"#eee"} }, {
              default: _withCtx(() => [
                _createVNode(_component_bs_row, { class: "my-2" }, {
                  default: _withCtx(() => [
                    (_ctx.url)
                      ? (_openBlock(), _createBlock(_component_bs_col, {
                          key: 0,
                          class: "min-width"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_5, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.Url) = $event)),
                                placeholder: "Url...",
                                tabIndex: "10"
                              }, null, 512), [
                                [_vModelText, _ctx.filter.Url]
                              ])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.sessionToken)
                      ? (_openBlock(), _createBlock(_component_bs_col, {
                          key: 1,
                          class: "min-width"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, [
                              _withDirectives(_createElementVNode("input", {
                                placeholder: "SessionToken...",
                                tabIndex: "10",
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.SessionToken) = $event))
                              }, null, 512), [
                                [_vModelText, _ctx.filter.SessionToken]
                              ])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.ip)
                      ? (_openBlock(), _createBlock(_component_bs_col, {
                          key: 2,
                          class: "min-width"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_7, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter.Ip) = $event)),
                                placeholder: "Ip...",
                                tabIndex: "10"
                              }, null, 512), [
                                [_vModelText, _ctx.filter.Ip]
                              ])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.userId)
                      ? (_openBlock(), _createBlock(_component_bs_col, {
                          key: 3,
                          class: "min-width"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_8, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter.UserId) = $event)),
                                placeholder: "UserId...",
                                tabIndex: "10"
                              }, null, 512), [
                                [_vModelText, _ctx.filter.UserId]
                              ])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.connectionId)
                      ? (_openBlock(), _createBlock(_component_bs_col, {
                          key: 4,
                          class: "min-width"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_9, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filter.ConnectionId) = $event)),
                                placeholder: "ConnectionId...",
                                tabIndex: "10"
                              }, null, 512), [
                                [_vModelText, _ctx.filter.ConnectionId]
                              ])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.projectLogGroupId)
                      ? (_openBlock(), _createBlock(_component_bs_col, {
                          key: 5,
                          class: "min-width"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_10, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filter.ProjectLogGroupId) = $event)),
                                placeholder: "projectLogGroupId...",
                                tabIndex: "10"
                              }, null, 512), [
                                [_vModelText, _ctx.filter.ProjectLogGroupId]
                              ])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.requestId)
                      ? (_openBlock(), _createBlock(_component_bs_col, {
                          key: 6,
                          class: "min-width"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_11, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filter.RequestId) = $event)),
                                placeholder: "RequestId...",
                                tabIndex: "10"
                              }, null, 512), [
                                [_vModelText, _ctx.filter.RequestId]
                              ])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.loggerName)
                      ? (_openBlock(), _createBlock(_component_bs_col, {
                          key: 7,
                          class: "min-width"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_12, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filter.LoggerName) = $event)),
                                placeholder: "LoggerName...",
                                tabIndex: "10"
                              }, null, 512), [
                                [_vModelText, _ctx.filter.LoggerName]
                              ])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.shortMessage)
                      ? (_openBlock(), _createBlock(_component_bs_col, {
                          key: 8,
                          class: "min-width"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_13, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filter.ShortMessage) = $event)),
                                placeholder: "ShortMessage...",
                                tabIndex: "10"
                              }, null, 512), [
                                [_vModelText, _ctx.filter.ShortMessage]
                              ])
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_bs_col, { class: "col-auto" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_tirscript3_button, {
                          active: "",
                          style: {"max-width":"20px","min-width":"20px"},
                          onOnClick: _ctx.clearFilter
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" X ")
                          ]),
                          _: 1
                        }, 8, ["onOnClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}