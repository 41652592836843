
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import ListViewItem from "tirscript3-list-view/src/ListViewItem";
import DefaultModel from "tirscript3-component-models/src/defaultModel";
import FilterModel from "@/views/page/components/filter-model";
import GetAllProjectLogGroupsResponse from "@/dataSource/api/logger-micro-service/services/models/response/GetAllProjectLogGroupsResponse";

@Options({
  name: "SelectSource",
})
export default class SelectSource extends Vue {
  @Prop({ default: null, type: Object }) filterDefault: FilterModel;
  sourceLogsList: ListViewItem<DefaultModel>[] = [];
  filter: FilterModel = new FilterModel();

  fullSourceLogsList: Array<GetAllProjectLogGroupsResponse> = [];

  @Watch("filterDefault", { deep: true })
  async onChangeFilterDefault() {
    if (this.filterDefault) {
      this.filter.ProjectLogGroupId = this.filterDefault.ProjectLogGroupId;
    }

    this.sourceLogsList.forEach((el) => {
      el.Selected = (el.Value as DefaultModel).id == this.filter.ProjectLogGroupId;
    });

    this.sourceLogsList = [
      new ListViewItem(
        new DefaultModel(null, "Все источники"),
        !this.filter.ProjectLogGroupId,
        false
      ),
    ];

    await this.getSourceLogsList();
  }

  created() {
    if (this.filterDefault)
      this.filter.ProjectLogGroupId = this.filterDefault.ProjectLogGroupId;

    this.sourceLogsList = [
      new ListViewItem(
        new DefaultModel(null, "Все источники"),
        !this.filter.ProjectLogGroupId,
        false
      ),
    ];
  }

  async mounted() {
    // await this.getSourceLogsList();
  }

  async getSourceLogsList() {
    this.sourceLogsList = [
      new ListViewItem(
        new DefaultModel(null, "Все источники"),
        !this.filter.ProjectLogGroupId,
        false
      ),
    ];

    let res = [];
    try {
      res = await this.$api.ProjectLogGroupService.getProjectLogGroupsAsync();
      this.fullSourceLogsList = res;
      res.forEach((item) => {
        this.sourceLogsList.push(
          new ListViewItem(
            new DefaultModel(
              item.Id,
              `${item.ProjectName}/${item.ServerName}(${item.Id})`
            ),
            item.Id == this.filter.ProjectLogGroupId,
            false
          )
        );
      });
      this.filter.ProjectLogGroupId = (this.sourceLogsList.filter(
        (el) => el.Selected === true
      )[0].Value as DefaultModel).id;
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      console.log("error", error);
    }
  }

  onChangeSource(elements: ListViewItem<DefaultModel>[]) {
    if (!elements?.length) return;
    let id = (elements[0].Value as DefaultModel).id;
    this.filter.ProjectLogGroupId = id;
    this.$emit("onChangeSource", this.filter.ProjectLogGroupId);
  }

  onChangeAutocomlite(value: string) {
    this.sourceLogsList = [];
    let filterSourceLogs = this.fullSourceLogsList.filter(
      (source) =>
        source.ProjectName.toLowerCase().indexOf(value.toLowerCase()) != -1 ||
        source.ServerName.toLowerCase().indexOf(value.toLowerCase()) != -1
    );

    filterSourceLogs.forEach((item) => {
      this.sourceLogsList.push(
        new ListViewItem(
          new DefaultModel(item.Id, `${item.ProjectName}/${item.ServerName}(${item.Id}`),
          item.Id == this.filter.ProjectLogGroupId,
          false
        )
      );
    });
    const res = this.sourceLogsList.filter((el) => el.Selected === true);
    if (res.length > 0) this.filter.ProjectLogGroupId = (res[0].Value as DefaultModel).id;
  }
}
